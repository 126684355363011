import { APICallInput } from '../types';
import { extendedFetch } from '../utils/extendedFetch';
import { getEndpointsDefinition } from '../utils/getEndpointsDefinition';
import { GetConfigurationResponse } from './getConfiguration.types';

/**
 * How to update Configuration in DEV
 * 1. Make sure you have the necessary access rights
 * 2. AWS Systems Manager > App Config > quirion > frontend
 * 3. Hosted configuration versions > "Create"
 * 4. Edit in "Text", then change to "JSON" (buggy), mind the duplicates in "default"
 * 5. "Create hosted configuration version"
 * 6. "Start deployment" > "Deployment strategy: DeployImmediately" > "Start Deployment"
 */

export const getConfiguration = (
  conf?: APICallInput,
): Promise<GetConfigurationResponse> => {
  const { endpointsDefinition } = conf ?? {
    endpointsDefinition: getEndpointsDefinition(),
  };
  return extendedFetch({
    endpointConfig: endpointsDefinition.endpoints.getConfiguration,
  }).then((response) => response.json());
};
