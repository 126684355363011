import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import type { ReactNode } from 'react';

import { shallowEqual, useSelector } from 'react-redux';

import { getConfiguration, getPendingPackageChangeOrders } from '@quirion/api';
import type { Campaign } from '@quirion/api/types';
import {
  AccountType,
  BusinessPartnerStatus,
  IpsStatus,
  SubscriptionPackage,
} from '@quirion/types';
import type { BusinessPartner, GenericCase, IPS, User } from '@quirion/types';

import type { State } from 'lib/redux/types';
import { getIsAllowedForCustomerReferral } from 'lib/utils';

import { isOrderAPendingSubscriptionChangeRequest } from '../utils/subscription/isOrderAPendingSubscriptionChangeRequest';
import { isWphgDataValid } from '../utils/wphg/isWphgDataValid';

/**
 * Checks if all business partners are using the new price packages
 * or an according order to change the subscription to a new price package.
 */
const getHasAllBPsMigratedOrInMigration = async (
  bps: Record<BusinessPartner['businessPartnerId'], BusinessPartner>,
) => {
  const bpsArray = Object.values(bps)?.filter(
    (bp) =>
      bp.status === BusinessPartnerStatus.Open ||
      bp.status === BusinessPartnerStatus.Pending,
  );

  if (!bpsArray?.length) return true;

  const promiseEvaluated = await Promise.all(
    bpsArray.map(async (bp) => {
      if (
        'subscriptionType' in bp &&
        bp.subscriptionType !== SubscriptionPackage.Unset
      ) {
        return true;
      }
      const openRequests = await getPendingPackageChangeOrders({
        payload: { businessPartnerId: bp.businessPartnerId },
      });
      return openRequests.some((openRequest) =>
        isOrderAPendingSubscriptionChangeRequest(openRequest),
      );
    }),
  );
  return promiseEvaluated.every((val) => val);
};

const getHasActiveBusinessPartner = (
  bps: Record<BusinessPartner['businessPartnerId'], BusinessPartner>,
): boolean =>
  Object.values(bps).some((bp) => bp.status !== BusinessPartnerStatus.Inclose);

export const hasSomeActiveIps = (allIps: Record<IPS['ipsId'], IPS>): boolean =>
  Object.values(allIps ?? []).some(
    (ips) =>
      ips.status !== IpsStatus.Inclose && ips.status === IpsStatus.Closed,
  );

export const hasSomeOpenIps = (allIps: Record<IPS['ipsId'], IPS>): boolean =>
  Object.values(allIps ?? []).some((ips) => ips.status === IpsStatus.Open);

export const getChecks = async ({
  user,
  businessPartner,
  cases,
  ips,
  customerReferralCampaign,
}: {
  user: User | Record<string, never>;
  businessPartner: Record<
    BusinessPartner['businessPartnerId'],
    BusinessPartner
  >;
  cases: GenericCase[];
  ips: Record<IPS['ipsId'], IPS>;
  customerReferralCampaign: Campaign | undefined;
}) => {
  const checksObj: ChecksContextState = {
    hasIdent: user?.identStatus,
    hasAllBPsMigrated: await getHasAllBPsMigratedOrInMigration(businessPartner),
    hasNoSingleAccount:
      typeof businessPartner === 'object' &&
      !Array.isArray(businessPartner) &&
      businessPartner !== null &&
      !Object.values(businessPartner).some(
        (bp) => bp.accountType === AccountType.SingleAccount,
      ),
    hasNoSingleAccountCase:
      Array.isArray(cases) &&
      !cases.some((c) => c?.accountType === AccountType.SingleAccount),
    get newSingleAccountAllowed() {
      return (
        this.hasAllBPsMigrated &&
        this.hasNoSingleAccount &&
        this.hasNoSingleAccountCase
      );
    },
    hasBP: businessPartner && !!Object.keys(businessPartner).length,
    hasOpenBP(businessPartnerId: BusinessPartner['businessPartnerId']) {
      return (
        businessPartner?.[businessPartnerId]?.status ===
        BusinessPartnerStatus.Open
      );
    },
    hasNotExpiredWphgBP(
      businessPartnerId: BusinessPartner['businessPartnerId'],
    ) {
      const wphgExpiresAt = businessPartner?.[businessPartnerId]?.wphgExpiresAt;

      if (!wphgExpiresAt) return false;

      return new Date(wphgExpiresAt) > new Date();
    },
    hasValidWphgBP(businessPartnerId: BusinessPartner['businessPartnerId']) {
      return isWphgDataValid(businessPartner?.[businessPartnerId]?.wphg);
    },
    newProductAllowed(businessPartnerId: BusinessPartner['businessPartnerId']) {
      return (
        this.hasIdent &&
        this.hasAllBPsMigrated &&
        this.hasOpenBP(businessPartnerId) &&
        this.hasNotExpiredWphgBP(businessPartnerId) &&
        this.hasValidWphgBP(businessPartnerId)
      );
    },
    hasSomeActiveBP: getHasActiveBusinessPartner(businessPartner),
    hasSomeActiveIps: hasSomeActiveIps(ips),
    hasSomeOpenIps: hasSomeOpenIps(ips),
    isAllowedForCustomerReferral:
      !!customerReferralCampaign &&
      getIsAllowedForCustomerReferral(businessPartner, ips),
  };

  return checksObj;
};

export type ChecksContextState = {
  /** Is `true` if user has identified himself through post ident or idNow.  */
  hasIdent: boolean;
  /** Is `true` if all bussiness partners have the new price package or an according order to change exists. */
  hasAllBPsMigrated: boolean;
  /**
   * Is `true` if the user has a single account business partner with the status "OPEN".
   */
  hasNoSingleAccount: boolean;
  /**
   * Is `true` if user has a case for a single account
   */
  hasNoSingleAccountCase: boolean;
  /**
   * Is `true` if user is eligable to add a new contract ({@link BusinessPartner|business partner}) with account type {@link AccountType.SingleAccount|singleAccount} to his account.
   */
  newSingleAccountAllowed: boolean;
  hasOpenBP: (
    BusinessPartnerId: BusinessPartner['businessPartnerId'],
  ) => boolean;
  /**
   * Is `true` if the business partner has a valid not yet expired wphg data set.
   */
  hasNotExpiredWphgBP: (
    BusinessPartnerId: BusinessPartner['businessPartnerId'],
  ) => boolean;
  /**
   * Is `true` if the business partner has valid wphg data.
   */
  hasValidWphgBP: (
    BusinessPartnerId: BusinessPartner['businessPartnerId'],
  ) => boolean;
  /**
   * Is `true` if user is eligable to open a new product (IPS) with the
   * given business partner ID.
   *
   * **IMPORTANT: This excludes a check of the business partner to have
   * a sufficient risk score to invest in topics.**
   */
  newProductAllowed: (
    BusinessPartnerId: BusinessPartner['businessPartnerId'],
  ) => boolean;
  /**
   * Is `true` if at least one of business partner doesn't have status `INCLOSE`.
   */
  hasSomeActiveBP: boolean;
  /**
   * Is `true` if there is at least one business partner.
   */
  hasBP: boolean;
  /**
   * Is `true` if at least one of the ips products status is not `INCLOSE` or `CLOSED`.
   */
  hasSomeActiveIps: boolean;
  /**
   * Is `true` if at least one of the ips products status is `OPEN`.
   */
  hasSomeOpenIps: boolean;
  /**
   * Checks if the user is allowed to refer a customer.
   */
  isAllowedForCustomerReferral: boolean;
  /** @todo getIsHighRiskBP to check for themenportfolio / selektive anlagechancen */
};
export type ChecksContextData = ChecksContextState;

const ChecksContext = createContext<ChecksContextData>({} as ChecksContextData);

export type ChecksActions = {
  type: 'update';
  payload: {
    [key in keyof ChecksContextState]: ChecksContextState[key];
  };
};

const reducer = (
  state: ChecksContextState,
  action: ChecksActions,
): ChecksContextState => {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const ChecksProvider = ({ children }: { children: ReactNode }) => {
  const [checks, dispatch] = useReducer(reducer, {} as ChecksContextState);
  const user = useSelector((state: State) => state.user, shallowEqual);
  const cases: GenericCase[] = useSelector(
    (state: State) => state.cases,
    (oldVal: GenericCase[], newVal: GenericCase[]) =>
      oldVal.length === newVal.length,
  );
  const businessPartner: Record<
    BusinessPartner['businessPartnerId'],
    BusinessPartner
  > = useSelector(
    (state: State) => state.businessPartner,
    (
      oldVal: Record<BusinessPartner['businessPartnerId'], BusinessPartner>,
      newVal: Record<BusinessPartner['businessPartnerId'], BusinessPartner>,
    ) => Object.keys(oldVal).length === Object.keys(newVal).length,
  );

  const updateChecks = (payload: ChecksActions['payload']) => {
    dispatch({ type: 'update', payload });
  };

  const ips: State['ips'] = useSelector(
    (state: State) => state.ips,
    (oldVal: State['ips'], newVal: State['ips']) =>
      Object.values(oldVal).length === Object.values(newVal).length,
  );

  const [customerReferralCampaign, setCustomerReferralCampaign] =
    useState<Campaign>();

  useEffect(() => {
    getConfiguration().then((res) =>
      setCustomerReferralCampaign(res.Campaigns?.KWK),
    );
  }, []);

  useEffect(() => {
    const initChecks = async () => {
      updateChecks(
        await getChecks({
          user,
          businessPartner,
          cases,
          ips,
          customerReferralCampaign,
        }),
      );
    };

    initChecks();
  }, [businessPartner, cases, customerReferralCampaign, ips, user]);

  return (
    <ChecksContext.Provider value={checks}>{children}</ChecksContext.Provider>
  );
};

export const useChecks = () => useContext(ChecksContext);
