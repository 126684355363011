import {
  type BusinessPartner,
  ConditionCategory,
  type IPS,
  IpsStatus,
} from '@quirion/types';

const CONTRACT_AGE = 3 * 30 * 24 * 60 * 60 * 1000; // 3 months in milliseconds
const CONTRACT_BALANCE = 100;

/**
 * Checks if the user is allowed to refer a customer.
 * @param contractList The list of contracts of the user.
 * @param productList The list of products of the user.
 */
export const getIsAllowedForCustomerReferral = (
  businessPartnerRecord: Record<
    BusinessPartner['businessPartnerId'],
    BusinessPartner
  >,
  ipsRecord: Record<IPS['ipsId'], IPS>,
) =>
  // at least one contract older than CONTRACT_AGE months
  Object.values(businessPartnerRecord).some(
    (businessPartner) =>
      businessPartner.openedAt &&
      new Date(businessPartner.openedAt) < new Date(Date.now() - CONTRACT_AGE),
  ) &&
  // at least one contract with balance > CONTRACT_BALANCE and an open portfolio investment
  Object.values(businessPartnerRecord).some(
    (businessPartner) =>
      businessPartner.balance > CONTRACT_BALANCE &&
      Object.values(ipsRecord).some(
        (ips) =>
          ips.businessPartnerId === businessPartner.businessPartnerId &&
          ips.category !== ConditionCategory.PensionPlan &&
          ips.status === IpsStatus.Open,
      ),
  );
